import React from "react";
import "./App.css";

function App() {
  return (
    <div className="glider-container">
      <div className="circle-wrapper">
        <div className="empty-circle"></div>

        <div
          className="circle"
          onClick={() => console.log("Circle clicked!")}
        ></div>
        <div className="empty-circle"></div>
      </div>
      <div className="circle-wrapper">
        <div className="empty-circle"></div>
        <div className="empty-circle"></div>
        <div
          className="circle"
          onClick={() => console.log("Circle clicked!")}
        ></div>
      </div>
      <div className="circle-wrapper">
        <div
          className="circle"
          onClick={() => console.log("Circle clicked!")}
        ></div>
        <div
          className="circle"
          onClick={() => console.log("Circle clicked!")}
        ></div>
        <div
          className="circle"
          onClick={() => console.log("Circle clicked!")}
        ></div>
      </div>
    </div>
  );
}

export default App;
